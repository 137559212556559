//题目列表
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        examinationID: null,
        grade: {
            value: 1,
            label: "一年级"
        }, //年级
        subject: {
            value: 1,
            label: "数学"
        }, //学科
        questionTypes: {
            id: 2,
            name: "单选"
        },
        chapterData: [],
        page: 1,
        tableData: [],
        total: 0, //总页数
        Variantid: '', //变式id
        flag: false,
        editId: 'add', //编辑id

    },
    getters: {

    },
    mutations: {
        setGrade: (state, grade) => {
            state.grade = grade
        },
        setQuestionTypes: (state, questionTypes) => {
            state.questionTypes = questionTypes
        },
        setEditId: (state, editId) => {
            state.editId = editId
        },
        setVariantid: (state, Variantid) => {
            state.Variantid = Variantid
        },
        setTableData: (state, tableData) => {
            state.tableData = tableData
        },
        setPage: (state, page) => {
            state.page = page
        },
        setTotal: (state, total) => {
            state.total = total
        },
        setPage: (state, page) => {
            state.page = page
        },
        setSubject: (state, subject) => {
            state.subject = subject
        },
        setChapter: (state, chapter) => {
            state.chapter = chapter
        },
        setChapterData: (state, chapterData) => {
            state.chapterData = chapterData
        },

        setCollapse: (state, toggle_collapsed) => {
            state.toggle_collapsed = toggle_collapsed
        },


    },
    actions: {},
    modules: {}
})