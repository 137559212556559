<template>
  <div class="box">
    <el-card class="box-card">
      <el-main class="box-card">
        <el-form :model="form" ref="form" label-width="100px" :rules="rules">
          <el-form-item label="年级：" prop="gradeLevel" required>
            <el-select
              v-model="form.gradeLevel"
              placeholder="年级"
              clearable
              size="mini"
            >
              <el-option
                v-for="item in levelEnum"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科：" prop="subjectId" required>
            <el-select v-model="form.subjectId" placeholder="学科" size="mini">
              <el-option
                v-for="item in subjectFilter"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="类型：" prop="variantSubjectValue" required>
            <el-select
              v-model="form.variantSubjectValue"
              placeholder="类型"
              size="mini"
              @change="changeVariantSubjectValue"
            >
              <el-option
                v-for="item in variantSubjectData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题干：" prop="title">
            <el-input
              size="mini"
              style="width: 500px"
              v-model="form.title"
              @focus="inputClick(form, 'title')"
            />
            <span v-html="form.title"></span>
          </el-form-item>
          <el-form-item
            v-if="
              form.variantSubjectValue == 1 || form.variantSubjectValue == 5
            "
            label="标答："
            prop="correct"
          >
            <el-input
              size="mini"
              style="width: 500px"
              v-model="form.correct"
              @focus="inputClick(form, 'correct')"
            />
            <span v-html="form.correct"></span>
          </el-form-item>
          <el-form-item label="解析：" prop="analyze">
            <el-input
              size="mini"
              style="width: 500px"
              v-model="form.analyze"
              @focus="inputClick(form, 'analyze')"
            />
            <span v-html="form.analyze"></span>
          </el-form-item>
          <el-form-item label="分数：" prop="score" required>
            <el-input-number
              size="mini"
              v-model="form.score"
              :precision="1"
              :step="1"
              :max="100"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="难度：" prop="difficult" required>
            <el-rate
              style
              v-model="form.difficult"
              class="question-item-rate"
            ></el-rate>
          </el-form-item>
          <el-form-item
            label="选项："
            v-if="
              form.variantSubjectValue == 2 ||
              form.variantSubjectValue == 3 ||
              form.variantSubjectValue == 4
            "
          >
            <el-main style="height: 300px">
              <el-table :data="topicData" style="width: 100%">
                <el-table-column label="序号" width="80" align="center">
                  <template slot-scope="{ row }">{{ row.sn }}</template>
                </el-table-column>
                <el-table-column
                  label="内容"
                  width="400"
                  show-overflow-tooltip
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div v-html="row.choiceContent"></div>
                  </template>
                </el-table-column>
                <el-table-column label="正确答案" width="110" align="center">
                  <template slot-scope="{ row }">
                    <!-- {{ row.isAnswer == 0 ? "否" : "是" }} -->
                    <el-select
                      size="mini"
                      v-model="row.isAnswer"
                      placeholder="请选择"
                      style="text-align: center; font-size: 14px"
                      :disabled="!ShowEd"
                    >
                      <el-option
                        v-for="item in IsAnswers"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="错误原因"
                  width="180"
                  show-overflow-tooltip
                  align="center"
                  v-if="form.variantSubjectValue != 4"
                >
                  <template slot-scope="{ row }">
                    <div v-html="row.cause"></div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="300">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="danger"
                      style="margin-left: 10px"
                      @click="handleDelete(scope.$index, scope.row)"
                      v-if="form.variantSubjectValue != 4 && ShowEd"
                      >删除</el-button
                    >
                    <el-button
                      size="mini"
                      @click="handleEdit(scope.$index, scope.row)"
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      @click="optionMove(scope.$index, scope.row, 'up')"
                      v-if="ShowEd"
                      >上移</el-button
                    >
                    <el-button
                      size="mini"
                      @click="optionMove(scope.$index, scope.row, 'down')"
                      v-if="ShowEd"
                      >下移</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-main>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="Reset">重置</el-button>
            <el-button
              type="success"
              @click="addTopic"
              v-if="
                form.variantSubjectValue == 2 || form.variantSubjectValue == 3
              "
              >添加选项</el-button
            >
            <el-button type="success" @click="showQuestion">预览</el-button>
            <el-button type="warning" @click="goreturn">返回</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-card>
    <!-- //编辑弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <el-form
        label-position="right"
        label-width="100px"
        :model="topicDataContent"
      >
        <el-form-item label="序号：">
          <el-input v-model="topicDataContent.sn"></el-input>
        </el-form-item>
        <el-form-item label="内容：">
          <mceEditor
            v-model="topicDataContent.choiceContent"
            ref="addEditorSpaceChoiceContent"
            :height="200"
          />
        </el-form-item>
        <el-form-item label="错误原因：">
          <mceEditor
            v-model="topicDataContent.cause"
            ref="addEditorSpaceCause"
            :height="200"
          />
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirm(1)">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="formdialogVisible"
      width="70%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <mceEditor
        v-model="form.IffroMaddEditor"
        ref="froMaddEditor"
        :height="200"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="editorConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- //预览 -->
    <el-dialog
      :visible.sync="QuestiondialogVisible"
      width="50%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="q-title" v-html="form.title"></div>
      <div
        class="q-content"
        v-if="
          form.variantSubjectValue == 2 ||
          form.variantSubjectValue == 3 ||
          form.variantSubjectValue == 4
        "
      >
        <span :key="item.sn" v-for="item in topicData" class="q-item-contain">
          <span class="q-item-prefix">{{ item.sn }}</span>
          <span v-html="item.choiceContent"></span>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mceEditor from "../k12SetQuestion/mceEditor.vue";
import {
  examTopicAdd,
  examTopicMessage,
  examTopicUpdate,
} from "../../../api/api";
import store from "../../../store/titleList/index.js";
export default {
  data() {
    return {
      //年级
      levelEnum: this.parameter.grade(),
      //科目
      subjectFilter: this.parameter.subject(),
      //题目类型
      variantSubjectData: this.parameter.variantSubjectData(),
      Ascll: 65, //记录ABCD选项的acdll码
      //选项
      topicData: [
        {
          sn: String.fromCharCode(65),
          choiceContent: "",
          isAnswer: 0,
          cause: "",
        },
        {
          sn: String.fromCharCode(66),
          choiceContent: "",
          isAnswer: 0,
          cause: "",
        },
      ],
      //选项细节描述
      topicDataContent: {
        choiceContent: "",
        cause: "",
        sn: "",
      },
      //验证
      rules: {
        gradeLevel: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        subjectId: [
          { required: true, message: "请选择学科", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入题干", trigger: "change" }],
        // analyze: [{ required: true, message: "请输入解析", trigger: "blur" }],
        score: [{ required: true, message: "请输入分数", trigger: "blur" }],
        // difficult: [{ required: true, message: "请输入分数", trigger: "blur" }],
        correct: [
          { required: true, message: "请选择正确答案", trigger: "change" },
        ],
      },
      // 右侧表单内容
      form: {
        gradeLevel: "", //年级
        subjectId: "", //学科
        variantSubjectValue: null, //题目类型
        title: "", //题干
        id: null,
        label: "",
        value: "",
        description: "",
        stemContent: "",
        difficult: 0, ////难度
        score: 0, //分数
        analyze: "", //解析
        IffroMaddEditor: "",
        recordName: "",
        correct: "", //标准答案
      },
      IsAnswers: [
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
      dialogVisible: false,
      formdialogVisible: false,
      QuestiondialogVisible: false,
      topicIndex: null, //题目编辑的下标
      VariantName: "", //变式名称
      editId: "add",
      ShowEd: true, //显示编辑
    };
  },
  mounted() {
    this.skip();
  },
  methods: {
    skip() {
      if (this.$route.query.id == "add") {
        this.editId = this.$route.query.id;
        this.init(2);
      } else {
        this.editId = this.$route.query.id;
        examTopicMessage({ id: this.editId }).then((res) => {
          this.disposeData(res.data);
          var obj = this.disposeSubData();
          obj["id"] = this.editId;
          examTopicUpdate(obj).then((res) => {
            if (res.message == "请求成功") {
              console.log("可以用来编辑");
            } else {
              this.$message.error(res.message);
              console.log("可以用来编辑");
              this.ShowEd = false;
            }
          });
        });
      }
    },
    //取消
    cancel() {
      this.formdialogVisible = false;
      this.form.IffroMaddEditor = "";
    },
    init(e) {
      this.topicData = [
        {
          sn: String.fromCharCode(65),
          choiceContent: "",
          isAnswer: 0,
          cause: "",
        },
        {
          sn: String.fromCharCode(66),
          choiceContent: "",
          isAnswer: 0,
          cause: "",
        },
      ];
      this.topicDataContent = {
        choiceContent: "",
        cause: "",
        sn: "",
      };
      this.form = {
        gradeLevel: this.form.gradeLevel, //年级
        subjectId: this.form.subjectId, //学科
        variantSubjectValue: e, //题目类型
        title: null, //题干
        id: null,
        label: "",
        value: "",
        description: "",
        stemContent: "",
        difficult: 0, ////难度
        score: 0, //分数
        analyze: null, //解析
        IffroMaddEditor: "",
        recordName: "",
        correct: null, //标准答案
      };
    },
    //选择类型
    changeVariantSubjectValue(e) {
      this.init(e);
    },
    //新增题目选项
    addTopic() {
      if (this.topicData.length == 0) {
        this.Ascll = 65;
      }
      if (this.topicData.length > 0) {
        var Ascll = this.topicData[this.topicData.length - 1];
        if (Ascll.sn.charCodeAt() >= 65 && Ascll.sn.charCodeAt() <= 90) {
          this.Ascll = Ascll.sn.charCodeAt() + 1;
        }
      }

      this.topicData.push({
        sn: String.fromCharCode(this.Ascll++),
        choiceContent: "",
        isAnswer: 0,
        cause: "",
      });
    },
    //题目选项编辑
    handleEdit(index, row) {
      this.topicDataContent.cause = "";
      var obj = {};
      obj = JSON.parse(JSON.stringify(row)); //row 编辑的内容  cause
      this.topicDataContent = obj;
      this.topicIndex = index; //记录编辑内容的下标
      this.topicDataContent.cause = row.cause;
      this.dialogVisible = true;
    },
    //题目选项删除
    handleDelete(index, row) {
      this.topicData.splice(index, 1);
      this.$message({
        message: "删除成功",
        type: "success",
      });
    },
    //选项移动
    optionMove(index, row, direction) {
      if (direction == "up") {
        if (index == 0) {
          this.$message.error("无法在向上移动了");
          return;
        }
        var data = [];
        data = JSON.parse(JSON.stringify(this.topicData));
        let meSn = row.sn;
        let sn = this.topicData[index - 1].sn;
        let bridge = JSON.parse(JSON.stringify(this.topicData[index - 1])); //保存的上一个全部内容
        data[index - 1] = row;
        data[index - 1].sn = sn;
        data[index] = bridge;
        data[index].sn = meSn;
        this.topicData = data;
      } else {
        if (this.topicData.length == index + 1) {
          this.$message.error("无法在向下移动了");
          return;
        }
        var data = [];
        data = JSON.parse(JSON.stringify(this.topicData));
        let meSn = row.sn;
        let sn = this.topicData[index + 1].sn;
        let bridge = JSON.parse(JSON.stringify(this.topicData[index + 1])); //保存的下一个全部内容
        data[index + 1] = row;
        data[index + 1].sn = sn;
        data[index] = bridge;
        data[index].sn = meSn;
        this.topicData = data;
      }
    },
    //关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.topicDataContent = {
            choiceContent: "",
            cause: "",
            sn: "",
          };
          this.cancel();
          done();
        })
        .catch((_) => {});
    },
    //判断选项是否有空值
    isOptionNull() {
      var a = false;
      var isAnswer = 0;
      if (this.form.difficult == 0) {
        this.$message.error("难度不可为0");
        a = true;
        return a;
      } else if (this.form.score <= 0) {
        this.$message.error("分数不可小于或者等于0");
        a = true;
        return a;
      }
      if (this.topicData.length > 0) {
        this.topicData.map((item) => {
          if (item.choiceContent == null || item.choiceContent == "") {
            a = true;
          }
          if (this.form.variantSubjectValue == 3) {
            //多选
            if (item.isAnswer == 1) {
              isAnswer++;
            }
          }
          if (this.form.variantSubjectValue == 2) {
            //单选
            if (item.isAnswer == 1) {
              isAnswer++;
            }
          }
          if (this.form.variantSubjectValue == 4) {
            //判断
            if (item.isAnswer == 1) {
              isAnswer++;
            }
          }
        });
      }
      if (a) {
        this.$message.error("选项内容不可有空");
        return a;
      }
      if (this.form.variantSubjectValue == 3) {
        if (!(isAnswer >= 2)) {
          this.$message.error("标准答案不可少于2个");
          a = true;
        }
        return a;
      }
      if (this.form.variantSubjectValue == 2) {
        if (!(isAnswer == 1)) {
          this.$message.error("标准答案可能为1个");
          a = true;
        }
        return a;
      }
      if (this.form.variantSubjectValue == 4) {
        if (!(isAnswer == 1)) {
          this.$message.error("判断的标准答案为1个");
          a = true;
        }
        return a;
      }

      if (this.form.variantSubjectValue == "") {
        this.$message.error("请题目类型");
        a = true;
        return a;
      } else if (this.serialNumber == "") {
        this.$message.error("请输入变式编号");
        a = true;
        return a;
      } else if (this.$refs.addEditorSpace.content == "") {
        this.$message.error("请输入题干内容");
        a = true;
        return a;
      } else if (this.topicData.length < 0) {
        this.$message.error("选项不可少于1个");
        a = true;
        return a;
      }

      return a;
    },
    //保存修改的内容选项
    dialogConfirm(data) {
      if (data == 1) {
      } else {
        //确认
        this.topicData[this.topicIndex].sn = this.topicDataContent.sn;
        this.topicData[this.topicIndex].choiceContent =
          this.$refs.addEditorSpaceChoiceContent.content;
        this.topicData[this.topicIndex].cause =
          this.$refs.addEditorSpaceCause.content;
      }
      this.dialogVisible = false;
      //清空.....弹窗中的内容
      this.topicDataContent = {
        choiceContent: "",
        cause: "",
        sn: "",
      };
    },
    //点击
    inputClick(object, parameterName) {
      this.form.IffroMaddEditor = "w1";
      this.form.recordName = parameterName;
      var data = JSON.parse(JSON.stringify(this.form[parameterName]));
      this.form.IffroMaddEditor = data;
      // this.from.parameterName = parameterName
      this.formdialogVisible = true;
    },
    //确认
    editorConfirm() {
      var name = this.form.recordName;
      this.form[name] = this.form.IffroMaddEditor;
      // this.$refs.froMaddEditor.content;
      this.formdialogVisible = false;
      this.form.IffroMaddEditor = "";
    },
    //处理提交数据--编辑
    disposeSubData() {
      var list = JSON.parse(JSON.stringify(this.topicData));
      list.map((res) => {
        res["serial"] = res.sn;
        res["content"] = res.choiceContent;
        res["answer"] = res.isAnswer;
        res["erroMessage"] = res.cause;
        delete res.sn;
        delete res.choiceContent;
        delete res.isAnswer;
        delete res.cause;
      });
      var params = {
        topicType: this.form.variantSubjectValue,
        topicAnalysis: this.form.analyze,
        topicScore: this.form.score,
        topicDegree: this.form.difficult,
        topicName: this.form.title,
        subject: this.form.subjectId,
        grade: this.form.gradeLevel,
        examTopicAnswerEntityList: list,
      };
      return params;
    },
    disposeData(data) {
      this.form.variantSubjectValue = data[0].topicType;
      this.form.analyze = data[0].topicAnalysis;
      this.form.score = data[0].topicScore;
      this.form.difficult = data[0].topicDegree;
      this.form.title = data[0].topicName;
      this.form.subjectId = data[0].subject;
      this.form.gradeLevel = data[0].grade;
      data[1][0].map((item) => {
        item["sn"] = item.serial;
        item["choiceContent"] = item.content;
        item["isAnswer"] = item.answer;
        item["cause"] = item.erroMessage;
      });
      this.topicData = data[1][0];
    },
    //提交
    submitForm() {
      this.$confirm("此操作将永久保存, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs.form.validate((valid) => {
            if (valid) {
              if (this.isOptionNull()) {
                return;
              }
              //新增;
              if (this.editId == "add") {
                examTopicAdd(this.disposeSubData()).then((res) => {
                  if (res.message == "请求成功") {
                    this.$store.commit("setBrowserRouter", false);
                    this.$message({
                      type: "success",
                      message: "保存成功,1秒后自动返回...",
                    });
                    setTimeout(() => {
                      this.$router.push({ name: "titleList", params: {} });
                    }, 1000);
                  } else {
                    this.$message.error(res.message);
                  }
                });
              } else {
                if (this.ShowEd) {
                  //直接可以编辑
                  var obj = this.disposeSubData();
                  obj["id"] = this.editId;
                  examTopicUpdate(obj).then((res) => {
                    if (res.message == "请求成功") {
                      this.$store.commit("setBrowserRouter", false);
                      this.$message({
                        type: "success",
                        message: "编辑成功,1秒后自动返回...",
                      });
                      setTimeout(() => {
                        this.$router.push({ name: "titleList", params: {} });
                      }, 1000);
                    } else {
                      this.$message.error(res.message);
                    }
                  });
                } else {
                  //无法直接编辑，只能修改内容
                }
              }
            } else {
              return false;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
        });
    },
    //预览
    showQuestion() {
      this.QuestiondialogVisible = true;
    },
    //重置
    Reset() {
      this.$confirm("你确认要重置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.skip();
          this.$message({
            type: "success",
            message: "重置成功",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置",
          });
        });
    },
    //返回
    goreturn() {
      this.$router.push({ name: "titleList", params: {} });
    },
  },
  computed: {
    // editId() {
    //   return store.state.editId;
    // },
  },
  components: {
    mceEditor,
  },
};
</script>
<style lang='less' scoped>
@import "./edit/index.less";
/deep/.tox-tinymce-aux {
  z-index: 2300 !important;
}
</style>